.home {
    .home-left-top {
        .bg-img {
            width: 100%;
            height: 200px;
            background-image: url('../../../Assets/Images/bg.jpg');
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            border-radius: 10px;
            overflow: hidden;
        }

        .profile-img {
            display: block;

            img {
                display: block;
                width: 150px;
                height: 150px;
                border-radius: 50%;
                background: #f5f5f5;
                padding: 8px;
                margin-top: -75px;
                margin-left: 20px;
            }

            @media (max-width: 768px) {
                img {
                    width: 120px;
                    height: 120px;
                    margin-top: -60px;
                    margin-left: 10px;
                }
            }
        }

        .profile-name {
            margin: 0;
            position: absolute;
            top: 280px;
            left: 430px;

            h1 {
                margin: 0;
                padding: 0;
            }

            h2 {
                margin: 0;
                padding: 0;
                font-weight: 400;
                color: #7e7e7e;
                font-size: 1.2rem;
            }

            @media (max-width: 1280px) {
                top: 265px;
                left: 410px;
            }

            @media (max-width: 768px) {
                top: 275px;
                left: 150px;

                h2 {
                    font-size: 0.8rem;
                }

                h1 {
                    font-size: 1.2rem;
                }
            }
        }
    }

    .home-continer {
        display: flex;
        gap: 40px;

        @media (max-width: 1280px) {
            flex-direction: column;
            gap: 0;
        }

        .home-left {
            width: 60%;
            order: 1;

            @media (max-width: 1280px) {
                width: 100%;
                order: 2;
                margin-top: 10px;
            }

            @media (max-width: 768px) {
                margin-top: 20px;
            }

            .updates-body {
                .sessions-header {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    h1 {
                        margin: 20px 0;
                        padding: 0;
                        font-size: 1.5rem;
                        font-weight: 500;
                        color: #999999;

                        @media (max-width: 1280px) {
                            font-size: 1.2rem;
                            margin: 20px auto;
                        }

                        @media (max-width: 768px) {
                            margin: 5px auto 10px auto;
                        }
                    }
                }

                .ant-list {
                    .ant-list-item {
                        margin: 0 0 20px 0;
                        padding: 20px;
                        border: none;
                        background: #fff;
                        border-radius: 10px;
                        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

                        .ant-divider {
                            margin: 1px 0;
                        }

                        .session-header {
                            display: flex;
                            align-items: start;
                            justify-content: space-between;

                            .meet-title {
                                margin: 0;
                                padding: 0;
                                font-size: 1rem;
                                font-weight: 500;
                                color: #3d394c;
                            }

                            .actions {
                                display: flex;
                                align-items: start;
                                justify-content: end;
                                width: 120px;
                            }

                            .attendance {
                                span {
                                    color: #848484;
                                    font-weight: 400;
                                }

                                .attended {
                                    color: #009c34;
                                }
                            }
                        }

                        h2 {
                            margin: 0;
                            padding: 0;
                            font-size: 0.9rem;
                            font-weight: 500;
                            color: #3d394c;
                            margin: 10px 0 10px 0;
                        }

                        .meeting-data {
                            .session-description {
                                .ql-editor {
                                    margin: 0;
                                    padding: 0;
                                    font-size: 0.9rem;
                                }
                            }

                            .meeting-link {
                                .meetlink-continer {
                                    section {
                                        display: flex;
                                        align-items: center;
                                        gap: 30px;

                                        .join-link {
                                            color: #673bf9;

                                            &:hover {
                                                color: #9d80ff;
                                            }
                                        }

                                        .copy-btn {
                                            color: #9d80ff;

                                            &:hover {
                                                color: #673bf9;
                                            }
                                        }

                                        div {
                                            margin: 0;
                                            padding: 0;
                                            display: flex;
                                            align-items: center;
                                            gap: 5px;

                                            span {
                                                color: #848484;
                                            }
                                        }
                                    }
                                }
                            }

                            .meeting-date-time {
                                margin: 0;
                                padding: 0;

                                .date-continer {
                                    margin: 10px 0;
                                    padding: 0;

                                    div {
                                        display: flex;
                                        align-items: center;
                                        gap: 10px;
                                        margin: 0;
                                        padding: 0;
                                        height: 30px;

                                        span {
                                            width: 15px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .home-right {
            margin-top: 70px;
            width: 40%;
            order: 2;

            @media (max-width: 1280px) {
                width: 100%;
                margin-top: 20px;
                order: 1;
            }

            @media (max-width: 768px) {
                margin-top: 0px;
            }

            .special-notes {
                background-color: #fff;
                min-height: 400px;
                border-radius: 10px;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                padding: 10px 20px;
                position: relative;

                .note-header {
                    position: absolute;
                    top: 10px;
                    right: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .title {
                    margin: 0;
                    padding: 0;
                    font-size: 1.2rem;
                    font-weight: 500;
                    text-align: center;

                    &::after {
                        content: '';
                        display: block;
                        width: 50px;
                        height: 2px;
                        background-color: #673bf9;
                        margin: 5px auto;
                    }
                }
            }
        }
    }
}