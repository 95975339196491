.cources {
    .cources-action-buttons {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 10px 0 20px 0;

        @media (max-width:768px) {
            margin: 0 0 10px 0;
        }

        .filters {
            display: flex;
            gap: 20px;
            align-items: center;

            @media (max-width: 768px) {
                flex-direction: column;
                gap: 10px;
            }

            .filter-search {
                display: flex;
                gap: 0px;
                align-items: center;


                h2 {
                    margin-right: 20px;
                }

                @media (max-width: 768px) {
                    justify-content: space-between;
                    width: 100%;

                    h2 {
                        font-size: 1.2rem;
                        margin: 0;
                    }
                }
            }
        }

        .btn {
            width: 100%;
            max-width: 200px;
            margin: 0 10px;
        }
    }

    .cources-body {
        .ant-list {
            width: 100%;
            padding: 0 5px;

            .course-card {
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                border-radius: 5px;
                overflow: hidden;
                margin-bottom: 40px;
                transition: all 0.5s ease-in-out;
                height: 450px;
                cursor: pointer;

                @media (max-width: 1280px) {
                    height: 100%;
                }

                &:hover {
                    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
                    transition: all 0.5s ease-in-out;
                    transform: scale(1.01);
                }

                .card-image-container {
                    position: relative;
                    overflow: hidden;



                    .cource-card-header-buttons {
                        position: absolute;
                        bottom: 10px;
                        right: 10px;
                        display: flex;
                        justify-content: space-between;
                        width: 98%;

                        .duration {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            color: #fff;
                            height: 32px;
                            background: #5a4992;
                            gap: 10px;
                            padding: 0 10px;
                        }

                        .btns {
                            display: flex;
                            gap: 10px;
                        }
                    }

                    .course-visiblity {
                        position: absolute;
                        top: 10px;
                        right: 5px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: #fff;
                        height: 32px;
                        gap: 10px;
                        border-radius: 50px;
                        padding: 5px 10px;
                        font-size: 0.8rem;
                    }

                    .all {
                        background: #5a4992;
                    }

                    .students {
                        background: #f5a623;
                    }

                    .teachers {
                        background: #00a8ff;
                    }

                    img {
                        display: block;
                        width: 100%;
                        height: 275px;
                        object-fit: cover;
                        transition: all 0.5s ease-in-out;

                        &:hover {
                            transform: scale(1.1);
                            transition: all 0.5s ease-in-out;
                        }
                    }
                }

                .card-body {
                    padding: 10px;
                    gap: 10px;

                    h3 {
                        margin: 0;
                        color: #000;
                    }

                    p {
                        color: #000;
                    }

                    .card-tags {
                        display: flex;
                        gap: 10px;
                        flex-wrap: wrap;
                        margin: 0;
                        padding: 0;
                        list-style: none;
                    }


                    .card-footer {
                        display: flex;
                        margin-top: 10px;
                        align-items: center;
                        width: 100%;

                        a {
                            width: 100%;

                            .ant-btn {
                                width: 100%;
                                background-color: #dad5ff;
                                color: #5a4992;

                                &:hover {
                                    background-color: #5a4992;
                                    color: #fff;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}