@import '../../../../../theme/variables.scss';

.ant-layout-sider {
    position: fixed !important;
    height: 100vh;
    width: $menu-slider-width !important;
    min-width: none !important;
    max-width: none !important;
    background: #f4eff9 !important;
    border-right: 1px solid #e8e8e8;
    // overflow: hidden;

    @media (max-width: 1280px) {
        width: $menu-slider-width-ss !important;
    }

    @media (max-width: 768px) {
        width: 0 !important;
        z-index: 1000;
    }

    .shade {
        position: absolute;
        width: 484px;
        height: 484px;
        top: 50%;
        left: 0%;
        background: radial-gradient(50% 50.00% at 50% 50.00%, rgba(158, 69, 209, 0.151) 0%, rgba(0, 0, 0, 0.00) 100%);
        border-radius: 100px;
        z-index: 0;
    }

    .shade2 {
        position: absolute;
        width: 312px;
        height: 312px;
        flex-shrink: 0;
        top: 80vh;
        left: -100px;
        background: radial-gradient(50% 50.00% at 50% 50.00%, #935bf35e 0%, rgba(0, 0, 0, 0.00) 100%);
        border-radius: 100px;
        z-index: 0;
    }


    .header-logo-vertical {
        width: 150px;
        margin: 10px auto 0 auto;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 100%;
        }
    }

    .ant-menu-item {
        line-height: 50px;
        font-weight: 500;
        color: $gray;
        padding-left: 20px;
        transition: all 0.3s ease-in-out;
        border-radius: 50px;
        width: calc(100% - 30px);
        margin: 10px 15px;

        &:hover {
            color: #673bf9 !important;
        }

        &.ant-menu-item-selected {
            color: $primary-color !important;
            background: $light-primary-color;
            color: #fff;
        }
    }
}


.ant-layout .ant-layout-sider-zero-width-trigger {
    top: 7px;
    background: #4124a0;
    right: -45px;
    border-radius: 6px !important;
}

.ant-layout .ant-layout-sider-zero-width-trigger::after {
    background: none !important;
}