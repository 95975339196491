.landing-page
    font-family: 'Poppins', sans-serif
    overflow: hidden
    header
        .header-nav-web
            display: flex
            justify-content: space-between
            align-items: center
            padding: 20px 200px
            background-color: #f6f4ff

            @media (max-width: 1285px)
                padding: 20px 100px
            @media (max-width: 800px)
                padding: 20px 50px
            @media (max-width: 768px)
                padding: 20px 20px
                flex-direction: column
                align-items: center
                .logo
                    margin: 0 auto
                    text-align: center
                    margin-bottom: 20px
                .links
                    margin: 0 auto
                    text-align: center
                    margin-bottom: 20px

            .logo
                a
                    text-decoration: none
                    img
                        width: 160px
                    h1
                        color: #12006d
                        font-size: 1.5rem
                        font-weight: 700
                        margin: 0
                    h2
                        color: #665a9e
                        font-weight: 400
                        font-size: 16px
                        letter-spacing: 12px
            .btns
                display: flex
                gap: 20px
                align-items: center

                @media (max-width: 768px)
                    flex-direction: column
                    gap: 0px
                    padding: 0
                .ant-btn
                    border-radius: 5px
                    display: flex
                    align-items: center
                    justify-content: center
                    padding: 20px 30px

                .signin
                    background-color: #4842ca
                    border: none
                    color: #fff
                    font-size: 1rem
                    font-weight: 600
                    width: 150px
                    height: 50px
                    order: 2
                    @media (max-width: 768px)
                        margin: 10px 0
                        order: 1
                .signup
                    background: none
                    border: none
                    box-shadow: none
                    color: #12006d
                    font-size: 1rem
                    font-weight: 600
                    height: 50px
                    width: 120px
                    margin: 0
                    padding: 0
                    order: 1
                    @media (max-width: 768px)
                        order: 2
                .translate
                    background: none
                    cursor: pointer
                    box-shadow: none
                    display: flex
                    align-items: center
                    justify-content: center
                    color: #12006d
                    font-size: 1rem
                    font-weight: 600
                    height: 50px
                    margin: 0
                    padding: 0
                    order: 3
                    @media (max-width: 768px)
                        order: 1
    .body
        .main-hero
            display: flex
            justify-content: center
            align-items: center
            flex-direction: column
            background-color: #f9f4ff
            padding: 50px 200px 0 200px

            @media (max-width: 1285px)
                padding: 50px 100px
            @media (max-width: 800px)
                padding: 50px 50px
            @media (max-width: 768px)
                padding: 20px 20px

            .hero-img
                width: 600px
                img
                    width: 100%
                @media (max-width: 768px)
                    width: 300px
            h1
                font-size: 2.5rem
                font-weight: 600
                width: 50%
                text-align: center
                margin: 20px 0
                h5
                    margin: 10px
                    font-size: 1.3em

                @media (max-width: 1285px)
                    width: 80%
                @media (max-width: 800px)
                    font-size: 2rem
                    margin-top: 0

                    h5
                        font-size: 1.2em
                @media (max-width: 768px)
                    font-size: 1.5rem
                    margin-top: 0
                    width: 90%
            h3
                font-size: 1.4rem
                font-weight: 400
                width: 70%
                text-align: center
                margin: 10px 0
                color: #727272
                line-height: 1.7
                margin: 20px 0 40px 0

                @media (max-width: 1285px)
                    width: 90%
                @media (max-width: 800px)
                    font-size: 1rem
                    margin-top: 0
                @media (max-width: 768px)
                    font-size: 1rem
                    margin-top: 0
                    width: 90%
            .hero-btns
                display: flex
                justify-content: center
                align-items: center
                padding: 10px 0
                gap: 20px

                @media (max-width: 768px)
                    flex-direction: column
                    gap: 0px
                    padding: 0

                .ant-btn
                    border-radius: 10px
                    display: flex
                    align-items: center
                    justify-content: center
                    padding: 20px 30px
                    margin: 20px 0
                    font-size: 1.2rem
                    font-weight: 600
                    width: 200px
                    height: 60px
                    border: none

                    @media (max-width: 768px)
                        width: 200px
                        margin: 10px 0
                        padding: 10px 0
                        font-size: 1rem
                        height: 50px
                        border-radius: 5px

        .section
            margin: 100px 0 0 0
            @media (max-width: 1285px)
                margin: 50px 0 0 0
            h2
                font-size: 2rem
                font-weight: 600
                text-align: center
                margin: 20px
                @media (max-width: 1285px)
                    font-size: 1.5rem
            h3
                font-size: 1.2rem
                font-weight: 350
                text-align: center
                color: #727272
                margin: 0
                padding: 0 100px
                line-height: 1.5
                @media (max-width: 1285px)
                    font-size: 1rem
                    padding: 0 50px

        .sesction-one
            .video-holder
                display: flex
                justify-content: center
                align-items: center

                iframe
                    width: 60%
                    height: 500px
                    @media (max-width: 1285px)
                        width: 80%
                    @media (max-width: 768px)
                        height: 300px
                        width: 90%
            .section-one-cards
                display: flex
                justify-content: center
                align-items: center
                flex-wrap: wrap
                gap: 50px
                padding: 50px 200px
                @media (max-width: 1285px)
                    padding: 50px 100px
                @media (max-width: 800px)
                    padding: 20px 20px
                    gap: 20px
                .card
                    width: 300px
                    height: 300px
                    border-radius: 20px
                    display: flex
                    flex-direction: column
                    padding: 20px
                    @media (max-width: 1285px)
                        margin: 0 auto
                        align-items: flex-start
                    .card-img
                        height: 120px
                        display: flex
                        justify-content: center
                        align-items: bottom
                        margin-bottom: 20px

                        @media (max-width: 1300px)
                            justify-content: start
                            margin: 0 auto
                        img
                            width: 120px
                    h1
                        font-size: 1.2rem
                        font-weight: 600
                        margin: 10px 0

                        @media (max-width: 1300px)
                            text-align: center
                    p
                        font-size: 1.1rem
                        font-weight: 400
                        color: #727272
                        margin: 0
                        margin-bottom: 20px
                        line-height: 1.5

                        @media (max-width: 1300px)
                            text-align: center
                    h5
                        font-size: 1rem
                        font-weight: 600
                        margin: 0

                    a
                        text-decoration: none
                        color: #747cb5
                        font-size: 1rem
                        font-weight: 600
                        @media (max-width: 1300px)
                            text-align: center
                            margin: 0 auto
        .flex-cont
            margin-top: 20px
            display: flex
            justify-content: space-between
            gap: 300px
            padding: 0px 300px
            @media (max-width: 1700px)
                gap: 50px
                padding: 0px 300px
            @media (max-width: 1300px)
                gap: 50px
                padding: 0px 200px
            @media (max-width: 1285px)
                gap: 50px
                padding: 0px 100px
            @media (max-width: 900px)
                gap: 50px
                padding: 0px 50px
            @media (max-width: 800px)
                gap: 50px
                padding: 0px 20px
            @media (max-width: 768px)
                gap: 50px
                padding: 0px 50px
                flex-direction: column

            .image
                min-width: 500px
                max-width: 700px
                @media (max-width: 1700px)
                    min-width: 200px
                    max-width: 300px
                @media (max-width: 800px)
                    min-width: 100px
                    max-width: 200px
                @media (max-width: 768px)
                    min-width: 200px
                    max-width: 300px
                    margin: 0 auto

                img
                    width: 100%
            .text
                padding: 0
                margin: 0
                display: flex
                justify-content: center
                flex-direction: column
                align-items: flex-start
                text-align: start

                h2
                    margin: 20px 0
                    text-align: start
                    @media (max-width: 768px)
                        text-align: center
                h3
                    margin: 0
                    text-align: start
                    padding: 0
                    @media (max-width: 768px)
                        text-align: center
        .section-three
            .left
                order: 1
                @media (max-width: 768px)
                    order: 2
            .right
                order: 2
                @media (max-width: 768px)
                    order: 1

        .section-six
            margin: 0px 300px
            border-radius: 10px
            padding: 100px 0
            margin-bottom: 100px

            @media (max-width: 1285px)
                margin: 0px 100px
                margin-bottom: 50px
            @media (max-width: 800px)
                margin: 0px 50px
                margin-bottom: 50px
            @media (max-width: 768px)
                margin: 20px 20px
                padding: 30px 0
                margin-bottom: 20px

            h3
                margin: 10px 0
                a
                    font-size: 1.5rem
                    text-decoration: none
                    color: #747cb5

        .section-two
            margin-bottom: 100px

            @media (max-width: 768px)
                margin-bottom: 70px

        .section-three
            margin-bottom: 100px

            @media (max-width: 768px)
                margin-bottom: 70px

    footer
        margin-top: 70px
        background-color: #f0ecffaa
        padding: 50px 300px
        gap: 200px
        display: flex
        justify-content: space-between

        @media (max-width: 1285px)
            padding: 50px 100px
            gap: 100px
        @media (max-width: 800px)
            padding: 50px 50px
            gap: 50px
        @media (max-width: 768px)
            padding: 50px 20px
            flex-direction: column
            text-align: center
            gap: 20px

            .logo
                margin: 0 auto
                text-align: center
                margin-bottom: 20px
            .links
                margin: 0 auto
                text-align: center
                margin-bottom: 20px

        div
            width: 100%
            .logo
                a
                    text-decoration: none
                    img
                        width: 150px
                    h1
                        color: #12006d
                        font-size: 1.5rem
                        font-weight: 700
                        margin: 0
                        h2
                            color: #665a9e
                            font-weight: 400
                            font-size: 16px
                            letter-spacing: 12px
        .footer-sec
            p
                margin: 5px 0
                line-height: 1.5
                span
                    margin-right: 10px
                a
                    text-decoration: none
                    color: #000
                    font-size: 1rem
                    margin: 5px 0
                    &:hover
                        color: #12006d
    .copy-right
        border-top: 1px solid #d9d9d9
        background-color: #f1ecffaa
        text-align: center
        padding: 20px 0
        p
            font-size: 0.9rem
            a
                text-decoration: none
                color: #4c517d
                font-weight: 500
                margin: 5px 0
                &:hover
                    color: #12006d

.register-model
    font-family: 'Poppins', sans-serif
    .reg-modal
        display: flex
        justify-content: center
        align-items: center
        flex-direction: column
        line-height: 1.8

        img
            width: 300px
            margin-bottom: 20px

            @media (max-width: 768px)
                width: 200px
                margin-bottom: 20px

        h1
            font-size: 1.4rem
            font-weight: 600
            margin: 30px 0 20px 0
            line-height: 1.2
            text-align: center
            color: #12006d

            @media (max-width: 768px)
                font-size: 1.2rem
                margin: 20px 0 10px 0

        h4
            font-size: 1rem
            font-weight: 400
            margin: 0
            color: #3c3c3c
            text-align: center

            @media (max-width: 768px)
                font-size: 0.9rem
                margin: 0
                color: #3c3c3c
                text-align: center

            span
                color: #524a7c
                font-weight: 600
                a
                    text-decoration: none
                    color: #524a7c
                    font-weight: 600
                    &:hover
                        color: #12006d
        h5
            font-size: 1.1rem
            font-weight: 500
            margin: 20px 0 5px 0
            color: #727272
            text-align: center

            @media (max-width: 768px)
                font-size: 1rem
                margin: 10px 0 5px 0
                color: #727272
                text-align: center

        .reg-modal-btns
            display: flex
            margin: 20px 0 20px 0
            justify-content: center
            align-items: center
            gap: 10px

            @media (max-width: 768px)
                flex-direction: column
                gap: 0px
                padding: 0
                margin: 10px 0 10px 0

            .ant-btn
                border-radius: 5px
                display: flex
                align-items: center
                justify-content: center
                padding: 10px 30px
                font-size: 1rem
                font-weight: 600
                width: 200px
                height: 40px

                @media (max-width: 768px)
                    margin: 10px 0
                    padding: 10px 0
                    font-size: 1rem
                    height: 40px
            .whtsapp-btn
                background-color: #12006d
                border: none
                color: #fff
            .call-btn
                background: none
                border: 1px solid #12006d
                box-shadow: none
                color: #12006d
